import React from "react"
import styled from "styled-components"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Embeds from "../components/embeds";

import { Wrapper } from "../components/layout"

const Container = styled.div`
  padding: 8rem 0;
  width: 100%;
  height: 100%;
  min-height: 80vh;
  background: white;
  color: black;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  text-align: left;

  li {
    padding-left: 1rem;
  }

  table {
    margin: 1rem 0 2rem !important;
  }

  th {
    border: 1px solid black;
    padding: 1rem !important;
  }

  td {
    border: 1px solid black;
    padding: 1rem !important;
  }

  h2 {
    margin-top: 4rem;
  }
`

const HeadedNumberBlock = ({ title, items, start }) => (
  <>
    <h2>{title}</h2>
    <ol start={start}>
      {items.map(({ preamble, subPoints, body, postamble }) => (
        <li>
          {preamble && <p>{preamble}</p>}
          {body && body}
          {subPoints && (
            <ol type="a">
              {subPoints.map(subPoint => (
                <li>{subPoint}</li>
              ))}
            </ol>
          )}
          {postamble && <p>{postamble}</p>}
        </li>
      ))}
    </ol>
  </>
)

const PrivacyPolicy = () => (
  <Layout navFill>
    <SEO title="Privacy Policy" />
    <Embeds />
    <Container>
      <Wrapper>
        <h1>PRIVACY POLICY</h1>
        <p>
          This privacy policy applies between you, the User of this Website
          and Verdn Ltd, the owner and provider of this Website. Verdn Ltd takes
          the privacy of your information very seriously. This privacy policy
          applies to our use of any and all Data collected by us or provided by
          you in relation to your use of the Website.
        </p>
        <p>
          <b>Please read this privacy policy carefully.</b>
        </p>
        <HeadedNumberBlock
          title="Definitions and interpretation"
          start="1"
          items={[
            {
              preamble: (
                <>In this privacy policy, the following definitions are used:</>
              ),
              body: (
                <>
                  <table>
                    <tbody>
                      <tr>
                        <td>
                          <b>Data</b>
                        </td>
                        <td>
                          collectively all information that you submit to Verdn
                          Ltd via the Website. This definition incorporates,
                          where applicable, the definitions provided in the Data
                          Protection Laws;
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <b>Cookies</b>
                        </td>
                        <td>
                          a small text file placed on your computer by this
                          Website when you visit certain parts of the Website
                          and /or when you use certain features of the Website.
                          Details of the cookies used by this Website are set
                          out in the clause below (<b>Cookies</b>);
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <b>Data Protection Laws</b>
                        </td>
                        <td>
                          any applicable law relating to the processing of
                          personal Data, including but not limited to the
                          Directive 96/46/EC (Data Protection Directive) or the
                          GDPR, and any national implementing laws, regulations
                          and secondary legislation, for as long as the GDPR is
                          effective in the UK;
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <b>GDPR</b>
                        </td>
                        <td>
                          the General Data Protection Regulation (EU) 2016/679;
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <b>Verdn Ltd, we</b> or <b>us</b>
                        </td>
                        <td>
                          Verdn Ltd, a company incorporated in England and Wales
                          with registered number 12444475 whose registered
                          office is at 1E Mentmore Terrace, London, E8 3DQ;
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <b>UK and EU Cookie Law</b>
                        </td>
                        <td>
                          the Privacy and Electronic Communications (EC
                          Directive) Regulations 2003 as amended by the Privacy
                          and Electronic Communications (EC Directive)
                          (Amendment) Regulations 2011;
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <b>User</b> or <b>you</b>
                        </td>
                        <td>
                          any third party that accesses the Website and is not
                          either (i) employed by Verdn Ltd and acting in the
                          course of their employment or (ii) engaged as a
                          consultant or otherwise providing services to Verdn
                          Ltd and accessing the Website in connection with the
                          provision of such services; and
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <b>Website</b>
                        </td>
                        <td>
                          the website that you are currently using, verdn.com,
                          and any sub-domains of this site unless expressly
                          excluded by their own terms and conditions.
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </>
              ),
            },
            {
              preamble: (
                <>
                  In this privacy policy, unless the context requires a
                  different interpretation:
                </>
              ),
              subPoints: [
                <>the singular includes the plural and vice versa;</>,
                <>
                  references to sub-clauses, clauses, schedules or appendices
                  are to sub-clauses, clauses, schedules or appendices of this
                  privacy policy;
                </>,
                <>
                  a reference to a person includes firms, companies, government
                  entities, trusts and partnerships;
                </>,
                <>
                  "including" is understood to mean "including without
                  limitation";
                </>,
                <>
                  reference to any statutory provision includes any modification
                  or amendment of it;
                </>,
                <>
                  the headings and sub-headings do not form part of this privacy
                  policy.
                </>,
              ],
            },
          ]}
        />
        <HeadedNumberBlock
          title="Scope of this privacy policy"
          start="3"
          items={[
            {
              preamble: (
                <>
                  This privacy policy applies only to the actions of Verdn Ltd
                  and Users with respect to this Website. It does not extend to
                  any websites that can be accessed from this Website including,
                  but not limited to, any links we may provide to social media
                  websites.
                </>
              ),
            },
            {
              preamble: (
                <>
                  For purposes of the applicable Data Protection Laws, Verdn Ltd
                  is the "data controller". This means that Verdn Ltd determines
                  the purposes for which, and the manner in which, your Data is
                  processed.
                </>
              ),
            },
          ]}
        />
        <HeadedNumberBlock
          title="Data collected"
          start="5"
          items={[
            {
              preamble: (
                <>
                  We may collect the following Data, which includes personal
                  Data, from you:
                </>
              ),
              subPoints: [
                <>gender;</>,
                <>
                  contact Information such as email addresses and telephone
                  numbers;
                </>,
                <>
                  demographic information such as postcode, preferences and
                  interests;
                </>,
                <>IP address (automatically collected);</>,
                <>web browser type and version (automatically collected);</>,
                <>operating system (automatically collected);</>,
                <>
                  a list of URLs starting with a referring site, your activity
                  on this Website, and the site you exit to (automatically
                  collected);
                </>,
              ],
              postamble: (
                <>in each case, in accordance with this privacy policy.</>
              ),
            },
          ]}
        />
        <HeadedNumberBlock
          title="How we collect Data"
          start="6"
          items={[
            {
              preamble: <>We collect Data in the following ways:</>,
              subPoints: [
                <>data is given to us by you; and</>,
                <>data is collected automatically.</>,
              ],
            },
          ]}
        />
        <HeadedNumberBlock
          title="Data that is given to us by you"
          start="7"
          items={[
            {
              preamble: (
                <>
                  Verdn Ltd will collect your Data in a number of ways, for
                  example:
                </>
              ),
              postamble: (
                <>in each case, in accordance with this privacy policy.</>
              ),
              subPoints: [
                <>
                  when you contact us through the Website, by telephone, post,
                  e-mail or through any other means;
                </>,
                <>
                  when you register with us and set up an account to receive our
                  products/services;
                </>,
                <>
                  when you complete surveys that we use for research purposes
                  (although you are not obliged to respond to them);
                </>,
                <>
                  when you make payments to us, through this Website or
                  otherwise;
                </>,
                <>
                  when you elect to receive marketing communications from us;
                </>,
                <>when you use our services;</>,
              ],
            },
          ]}
        />
        <HeadedNumberBlock
          title="Data that is collected automatically"
          start="8"
          items={[
            {
              preamble: (
                <>
                  To the extent that you access the Website, we will collect
                  your Data automatically, for example:
                </>
              ),
              subPoints: [
                <>
                  we automatically collect some information about your visit to
                  the Website. This information helps us to make improvements to
                  Website content and navigation, and includes your IP address,
                  the date, times and frequency with which you access the
                  Website and the way you use and interact with its content.
                </>,
                <>
                  we will collect your Data automatically via cookies, in line
                  with the cookie settings on your browser. For more information
                  about cookies, and how we use them on the Website, see the
                  section below, headed "Cookies".;
                </>,
              ],
            },
          ]}
        />
        <HeadedNumberBlock
          title="Our use of Data"
          start="9"
          items={[
            {
              preamble: (
                <>
                  Any or all of the above Data may be required by us from time
                  to time in order to provide you with the best possible service
                  and experience when using our Website. Specifically, Data may
                  be used by us for the following reasons:
                </>
              ),
              subPoints: [
                <>internal record keeping;</>,
                <>improvement of our products / services;</>,
                <>
                  transmission by email of marketing materials that may be of
                  interest to you;
                </>,
                <>
                  contact for market research purposes which may be done using
                  email, telephone, fax or mail. Such information may be used to
                  customise or update the Website;
                </>,
              ],
              postamble: (
                <>in each case, in accordance with this privacy policy.</>
              ),
            },
            {
              preamble: (
                <>
                  We may use your Data for the above purposes if we deem it
                  necessary to do so for our legitimate interests. If you are
                  not satisfied with this, you have the right to object in
                  certain circumstances (see the section headed "Your rights"
                  below).
                </>
              ),
            },
            {
              preamble: (
                <>
                  For the delivery of direct marketing to you via e-mail, we'll
                  need your consent, whether via an opt-in or soft-opt-in:
                </>
              ),
              subPoints: [
                <>
                  soft opt-in consent is a specific type of consent which
                  applies when you have previously engaged with us (for example,
                  you contact us to ask us for more details about a particular
                  product/service, and we are marketing similar
                  products/services. Under "soft opt-in" consent, we will take
                  your consent as given unless you opt-out.
                </>,
                <>
                  for other types of e-marketing, we are required to obtain your
                  explicit consent; that is, you need to take positive and
                  affirmative action when consenting by, for example, checking a
                  tick box that we'll provide.
                </>,
                <>
                  if you are not satisfied about our approach to marketing, you
                  have the right to withdraw consent at any time. To find out
                  how to withdraw your consent, see the section headed "Your
                  rights" below.
                </>,
              ],
            },
            {
              preamble: (
                <>
                  When you register with us and set up an account to receive our
                  services, the legal basis for this processing is the
                  performance of a contract between you and us and/or taking
                  steps, at your request, to enter into such a contract.
                </>
              ),
            },
            {
              preamble: (
                <>
                  We may use your Data to show you Verdn Ltd adverts and other
                  content on other websites. If you do not want us to use your
                  data to show you Verdn Ltd adverts and other content on other
                  websites, please turn off the relevant cookies (please refer
                  to the section headed "Cookies" below).
                </>
              ),
            },
          ]}
        />
        <HeadedNumberBlock
          title="Who we share Data with"
          start="14"
          items={[
            {
              preamble: (
                <>
                  We may share your Data with the following groups of people for
                  the following reasons:
                </>
              ),
              subPoints: [
                <>
                  our employees, agents and/or professional advisors - to
                  undertake standard business activities;
                </>,
                <>
                  third party service providers who provide services to us which
                  require the processing of personal data - to track website
                  usage for analytics purposes and to inform our providers of
                  impact of relevant user data;
                </>,
              ],
              postamble: (
                <>in each case, in accordance with this privacy policy.</>
              ),
            },
          ]}
        />
        <HeadedNumberBlock
          title="Keeping Data secure"
          start="15"
          items={[
            {
              preamble: (
                <>
                  We will use technical and organisational measures to safeguard
                  your Data, for example:
                </>
              ),
              subPoints: [
                <>
                  access to your account is controlled by a password and a user
                  name that is unique to you.
                </>,
                <>we store your Data on secure servers.</>,
              ],
            },
            {
              preamble: (
                <>
                  Technical and organisational measures include measures to deal
                  with any suspected data breach. If you suspect any misuse or
                  loss or unauthorised access to your Data, please let us know
                  immediately by contacting us via this e-mail address:
                  contact@verdn.com.
                </>
              ),
            },
            {
              preamble: (
                <>
                  If you want detailed information from Get Safe Online on how
                  to protect your information and your computers and devices
                  against fraud, identity theft, viruses and many other online
                  problems, please visit www.getsafeonline.org. Get Safe Online
                  is supported by HM Government and leading businesses.
                </>
              ),
            },
          ]}
        />
        <HeadedNumberBlock
          title="Data retention"
          start="18"
          items={[
            {
              preamble: (
                <>
                  Unless a longer retention period is required or permitted by
                  law, we will only hold your Data on our systems for the period
                  necessary to fulfil the purposes outlined in this privacy
                  policy or until you request that the Data be deleted.
                </>
              ),
            },
            {
              preamble: (
                <>
                  Even if we delete your Data, it may persist on backup or
                  archival media for legal, tax or regulatory purposes.
                </>
              ),
            },
          ]}
        />
        <HeadedNumberBlock
          title="Your rights"
          start="20"
          items={[
            {
              preamble: (
                <>You have the following rights in relation to your Data:</>
              ),
              subPoints: [
                <>
                  <b>Right to access - </b> the right to request (i) copies of
                  the information we hold about you at any time, or (ii) that we
                  modify, update or delete such information. If we provide you
                  with access to the information we hold about you, we will not
                  charge you for this, unless your request is "manifestly
                  unfounded or excessive." Where we are legally permitted to do
                  so, we may refuse your request. If we refuse your request, we
                  will tell you the reasons why.
                </>,
                <>
                  <b>Right to correct - </b> the right to have your Data
                  rectified if it is inaccurate or incomplete.
                </>,
                <>
                  <b>Right to erase - </b> the right to request that we delete
                  or remove your Data from our systems.
                </>,
                <>
                  <b>Right to restrict our use of your Data - </b> the right to
                  "block" us from using your Data or limit the way in which we
                  can use it.
                </>,
                <>
                  <b>Right to data portability - </b> the right to request that
                  we move, copy or transfer your Data.
                </>,
                <>
                  <b>Right to object - </b> the right to object to our use of
                  your Data including where we use it for our legitimate
                  interests.
                </>,
              ],
            },
            {
              preamble: (
                <>
                  To make enquiries, exercise any of your rights set out above,
                  or withdraw your consent to the processing of your Data (where
                  consent is our legal basis for processing your Data), please
                  contact us via this e-mail address: contact@verdn. com.
                </>
              ),
            },
            {
              preamble: (
                <>
                  If you are not satisfied with the way a complaint you make in
                  relation to your Data is handled by us, you may be able to
                  refer your complaint to the relevant data protection
                  authority. For the UK, this is the Information Commissioner's
                  Office (ICO). The ICO's contact details can be found on their
                  website at https://ico.org.uk/.
                </>
              ),
            },
            {
              preamble: (
                <>
                  It is important that the Data we hold about you is accurate
                  and current. Please keep us informed if your Data changes
                  during the period for which we hold it.
                </>
              ),
            },
          ]}
        />
        <HeadedNumberBlock
          start="24"
          title="Transfers outside the European Economic Area"
          items={[
            {
              preamble: (
                <>
                  Data which we collect from you may be stored and processed in
                  and transferred to countries outside of the European Economic
                  Area (EEA). For example, this could occur if our servers are
                  located in a country outside the EEA or one of our service
                  providers is situated in a country outside the EEA. We also
                  share information with our group companies, some of which are
                  located outside the EEA.
                </>
              ),
            },
            {
              preamble: (
                <>
                  We will only transfer Data outside the EEA where it is
                  compliant with data protection legislation and the means of
                  transfer provides adequate safeguards in relation to your
                  data, eg by way of data transfer agreement, incorporating the
                  current standard contractual clauses adopted by the European
                  Commission, or by signing up to the EU-US Privacy Shield
                  Framework, in the event that the organisation in receipt of
                  the Data is based in the United States of America.
                </>
              ),
            },
            {
              preamble: (
                <>
                  To ensure that your Data receives an adequate level of
                  protection, we have put in place appropriate safeguards and
                  procedures with the third parties we share your Data with.
                  This ensures your Data is treated by those third parties in a
                  way that is consistent with the Data Protection Laws.
                </>
              ),
            },
          ]}
        />
        <HeadedNumberBlock
          start="27"
          title="Links to other websites"
          items={[
            {
              preamble: (
                <>
                  This Website may, from time to time, provide links to other
                  websites. We have no control over such websites and are not
                  responsible for the content of these websites. This privacy
                  policy does not extend to your use of such websites. You are
                  advised to read the privacy policy or statement of other
                  websites prior to using them.
                </>
              ),
            },
          ]}
        />
        <HeadedNumberBlock
          start="28"
          title="Changes of business ownership and control"
          items={[
            {
              preamble: (
                <>
                  Verdn Ltd may, from time to time, expand or reduce our
                  business and this may involve the sale and/or the transfer of
                  control of all or part of Verdn Ltd. Data provided by Users
                  will, where it is relevant to any part of our business so
                  transferred, be transferred along with that part and the new
                  owner or newly controlling party will, under the terms of this
                  privacy policy, be permitted to use the Data for the purposes
                  for which it was originally supplied to us.
                </>
              ),
            },
            {
              preamble: (
                <>
                  We may also disclose Data to a prospective purchaser of our
                  business or any part of it.
                </>
              ),
            },
            {
              preamble: (
                <>
                  In the above instances, we will take steps with the aim of
                  ensuring your privacy is protected.
                </>
              ),
            },
          ]}
        />
        <HeadedNumberBlock
          start="31"
          title="Cookies"
          items={[
            {
              preamble: (
                <>
                  This Website may place and access certain Cookies on your
                  computer. Verdn Ltd uses Cookies to improve your experience of
                  using the Website and to improve our range of services. Verdn
                  Ltd has carefully chosen these Cookies and has taken steps to
                  ensure that your privacy is protected and respected at all
                  times.
                </>
              ),
            },
            {
              preamble: (
                <>
                  All Cookies used by this Website are used in accordance with
                  current UK and EU Cookie Law.
                </>
              ),
            },
            {
              preamble: (
                <>
                  Before the Website places Cookies on your computer, you will
                  be presented with a message bar requesting your consent to set
                  those Cookies. By giving your consent to the placing of
                  Cookies, you are enabling Verdn Ltd to provide a better
                  experience and service to you. You may, if you wish, deny
                  consent to the placing of Cookies; however certain features of
                  the Website may not function fully or as intended.
                </>
              ),
            },
            {
              preamble: <>This Website may place the following Cookies:</>,
              body: (
                <table>
                  <thead>
                    <tr>
                      <th>Type of Cookie</th>
                      <th>Purpose</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>Strictly necessary cookies</td>
                      <td>
                        These are cookies that are required for the operation of
                        our website. They include, for example, cookies that
                        enable you to log into secure areas of our website, use
                        a shopping cart or make use of e-billing services.
                      </td>
                    </tr>
                    <tr>
                      <td>Analytical/performance cookies</td>
                      <td>
                        They allow us to recognise and count the number of
                        visitors and to see how visitors move around our website
                        when they are using it. This helps us to improve the way
                        our website works, for example, by ensuring that users
                        are finding what they are looking for easily.
                      </td>
                    </tr>
                    <tr>
                      <td>Functionality cookies</td>
                      <td>
                        These are used to recognise you when you return to our
                        website. This enables us to personalise our content for
                        you, greet you by name and remember your preferences
                        (for example, your choice of language or region).
                      </td>
                    </tr>
                    <tr>
                      <td>Targeting cookies</td>
                      <td>
                        These cookies record your visit to our website, the
                        pages you have visited and the links you have followed.
                        We will use this information to make our website and the
                        advertising displayed on it more relevant to your
                        interests. We may also share this information with third
                        parties for this purpose.
                      </td>
                    </tr>
                  </tbody>
                </table>
              ),
            },
            {
              preamble: (
                <>
                  You can choose to enable or disable Cookies in your internet
                  browser. By default, most internet browsers accept Cookies but
                  this can be changed. For further details, please consult the
                  help menu in your internet browser.
                </>
              ),
            },
            {
              preamble: (
                <>
                  You can choose to delete Cookies at any time; however you may
                  lose any information that enables you to access the Website
                  more quickly and efficiently including, but not limited to,
                  personalisation settings.
                </>
              ),
            },
            {
              preamble: (
                <>
                  It is recommended that you ensure that your internet browser
                  is up-to-date and that you consult the help and guidance
                  provided by the developer of your internet browser if you are
                  unsure about adjusting your privacy settings.
                </>
              ),
            },
            {
              preamble: (
                <>
                  For more information generally on cookies, including how to
                  disable them, please refer to aboutcookies.org. You will also
                  find details on how to delete cookies from your computer.
                </>
              ),
            },
          ]}
        />
        <HeadedNumberBlock
          start="39"
          title="General"
          items={[
            {
              preamble: (
                <>
                  You may not transfer any of your rights under this privacy
                  policy to any other person. We may transfer our rights under
                  this privacy policy where we reasonably believe your rights
                  will not be affected.
                </>
              ),
            },
            {
              preamble: (
                <>
                  If any court or competent authority finds that any provision
                  of this privacy policy (or part of any provision) is invalid,
                  illegal or unenforceable, that provision or part-provision
                  will, to the extent required, be deemed to be deleted, and the
                  validity and enforceability of the other provisions of this
                  privacy policy will not be affected.
                </>
              ),
            },
            {
              preamble: (
                <>
                  Unless otherwise agreed, no delay, act or omission by a party
                  in exercising any right or remedy will be deemed a waiver of
                  that, or any other, right or remedy.
                </>
              ),
            },
            {
              preamble: (
                <>
                  This Agreement will be governed by and interpreted according
                  to the law of England and Wales. All disputes arising under
                  the Agreement will be subject to the exclusive jurisdiction of
                  the English and Welsh courts.
                </>
              ),
            },
          ]}
        />
        <HeadedNumberBlock
          start="43"
          title="Changes to this privacy policy"
          items={[
            {
              preamble: (
                <>
                  Verdn Ltd reserves the right to change this privacy policy as
                  we may deem necessary from time to time or as may be required
                  by law. Any changes will be immediately posted on the Website
                  and you are deemed to have accepted the terms of the privacy
                  policy on your first use of the Website following the
                  alterations.
                </>
              ),
              postamble: (
                <>You may contact Verdn Ltd by email at contact@verdn.com.</>
              ),
            },
          ]}
        />
        <HeadedNumberBlock
          start="44"
          title="Attribution"
          items={[
            {
              preamble: (
                <>
                  This privacy policy was created using a document from Rocket
                  Lawyer (https://www.rocketlawyer.com/gb/en).{" "}
                  <b>15 June 2020</b>
                </>
              ),
            },
          ]}
        />
      </Wrapper>
    </Container>
  </Layout>
)

export default PrivacyPolicy
